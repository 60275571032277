(function() {
  'use strict';
  angular.module('dougAlderman', []);
})();


(function() {
  'use strict';

  function hmFooter() {

    return {
      restrict: 'E',
      templateUrl: '../directive_html/hmFooter.html',
      scope: {},
      link: function(scope) {

        scope.mobileNavbarSelectedFooter = false;

        scope.activateMobileNavbarFooter = function() {
          if (scope.mobileNavbarSelectedFooter === false)
            scope.mobileNavbarSelectedFooter = true;
          else // if Navbar is already active
            scope.mobileNavbarSelectedFooter = false;
	    };

	    scope.deactivateMobileNavbarFooter = function() {
		  scope.mobileNavbarSelectedFooter = false;
	    };
      }
    };

  }

  angular .module('dougAlderman')
          .directive('hmFooter', [hmFooter]);

}());

(function() {
  'use strict';

  function hmHeader() {

    return {
      restrict: 'E',
      templateUrl: '../directive_html/hmHeader.html',
      scope: {},
      link: function(scope) {

        scope.mobileNavbarSelected = false;

        scope.activateMobileNavbar = function() {
          if (scope.mobileNavbarSelected === false)
            scope.mobileNavbarSelected = true;
          else // if Navbar is already active
            scope.mobileNavbarSelected = false;
	    };

	    scope.deactivateMobileNavbar = function() {
		  scope.mobileNavbarSelected = false;
	    };
      }
    };

  }

  angular .module('dougAlderman')
          .directive('hmHeader', [hmHeader]);

}());
